<template>
  <div class="header-naga">
    <div class="arrow-header-naga">
      <div class="arrow-header-naga-content">
        <p><i class="arrow down"></i></p>
      </div>
    </div>

    <div class="container">
      <div class="header-naga-content">
        <div class="header-naga-content-logo">
          <img src="../../../assets/lps/naga/logo.svg" alt="" />
        </div>

        <div class="header-naga-content-texts">
          <div class="header-naga-content-header">
            <h1>
              "I learned how to trade all the hottest tech stocks in one month"
            </h1>
          </div>

          <div class="header-naga-content-p">
            <p>
              "I had no experience in online forex trading. I saw that tech
              stocks like Amazon, Netflix and Zoom were flying. A good friend
              who is a CFD trader told me it is not too late to start trading
              stocks online and that with some training and practice I could
              learn how to trade from home. Thanks to her I signed up and got
              free training from a professional, along with educational
              materials. Once I felt more comfortable with what I had learned
              and had practiced on a demo account, I started trading for myself
              slowly. Within one month I felt very comfortable to trade Forex
              and Stocks and I know that the more I learn about trading the
              better my results!"
            </p>
          </div>
          <div class="header-naga-content-p-500">
            <p>
              "I had no experience in online forex trading. I saw that tech
              stocks like Amazon, Netflix and Zoom were flying. A good friend
              who is a CFD trader told me it is not too late to start trading
              stocks online and that with some training and practice I could
              learn how to trade from home. Thanks to her I signed up and got
              free training from a professional, along with educational
              materials. Once I felt more comfortable with what I had learned
              and had practiced on a demo account, I started trading for myself
              slowly. Within one month I felt very comfortable to trade Forex
              and Stocks and I know that the more I learn about trading the
              better my results!"
            </p>
          </div>

          <div class="header-naga-content-qoute bold-text">
            <span>Amada S. - School teacher</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.header-naga-content-p-500 {
  display: none;
}
.arrow {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-header-naga-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.arrow-header-naga {
  transform: translateX(-50%) !important;
  background: #3179ff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
  /* transform: translateX(-50%) !important; */
}

/* @keyframes test {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0);
  }
} */
.header-naga {
  position: relative;
  padding-bottom: 100px;
  background: url("../../../assets/lps/naga/header-bg.jpg") no-repeat
    center/cover;
}

.header-naga-content-logo {
  padding-top: 36px;
}

.header-naga-content-header h1 {
  font-size: 46px;
  padding-top: 128px;
  line-height: 54px;
}

.header-naga-content-texts {
  max-width: 674px;
  width: 100%;
}

.header-naga-content-p {
  padding-top: 28px;
}
.header-naga-content-p p {
  font-size: 21px;
}

.header-naga-content-qoute {
  padding-top: 30px;
}
.header-naga-content-qoute span {
  font-size: 21px;
  font-weight: bold;
}

@media (max-width: 1024px) {
  .header-naga {
    padding-bottom: 170px;
    background: url("../../../assets/lps/naga/header-bg-1024.jpg") no-repeat
      center/cover;
  }

  .header-naga-content-header h1 {
    font-size: 30px;
    padding-top: 30px;
    line-height: 36px;
  }

  .header-naga-content-texts {
    max-width: 385px;
    width: 100%;
  }

  .header-naga-content-p p {
    font-size: 18px;
  }

  .header-naga-content-logo {
    padding-top: 36px;
  }

  .header-naga-content-qoute span {
    font-size: 18px;
    font-weight: normal;
  }
}
@media (max-width: 768px) {
  .header-naga {
    padding-bottom: 170px;
    background: url("../../../assets/lps/naga/header-bg-768.jpg") no-repeat
      center/cover;
  }
  .header-naga-content-header h1 {
    font-size: 24px;
    padding-top: 30px;
  }

  .header-naga-content-texts {
    max-width: 380px;
    width: 100%;
  }

  .header-naga-content-p {
    padding-top: 15px;
  }
}

@media (max-width: 500px) {
  .header-naga-content-p-500 {
    display: block;
    margin-top: 10px;
  }
  .header-naga-content-p {
    display: none;
  }
  .header-naga {
    height: 990px;
    padding-bottom: 170px;
    background: url("../../../assets/lps/naga/header-bg-500.jpg") no-repeat
      center;
    background-size: cover;
  }
  .header-naga-content-logo {
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  .header-naga-content-header h1 {
    font-size: 24px;
    padding-top: 15px;
    line-height: 29px;
    text-align: center;
  }

  .header-naga-content-texts {
    max-width: 280px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}

/* @media (max-width: 360px) {
  .header-naga {
    height: 1240px;
    padding-bottom: 170px;
    background: url("../../../assets/lps/naga/header-bg-320.jpg") no-repeat
      center/cover;
  }
} */
</style>